*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#components-layout-demo-custom-trigger .trigger {
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

:where(.css-dev-only-do-not-override-1vtf12y).ant-menu .ant-menu-item{
  white-space: initial !important;
}
:where(.css-1vtf12y).ant-menu .ant-menu-item{
  white-space: unset;
}
.site-layout .site-layout-background {
  background: #fff;

}
.ant-pagination-options-size-changer.ant-select{
  display: none;
}
/* .ant-menu-inline .ant-menu-item{
  padding: 0 10px;
} */